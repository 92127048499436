var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('p',{staticClass:"ml-2 mt-2"},[_vm._v(_vm._s(_vm.title))]),_c('v-row',{staticClass:"mt-4 mb-2",attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"12","sm":"5","md":"4"}},[_c('v-text-field',{staticClass:"caption",attrs:{"dense":"","hide-details":"","outlined":"","placeholder":_vm.$_strings.oracleData.SEARCH_INVOICE_ORACLE},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSearch($event)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{on:{"click":_vm.handleSearch}},[_vm._v(" mdi-magnify ")])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"calculate-widths":"","loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.totalData,"options":_vm.pagination,"footer-props":{
      showCurrentPage: true,
      showFirstLastPage: true,
      'items-per-page-options': _vm.$_item_per_page
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":"","icon":""},on:{"click":function () { return _vm.showDialogFormOracle(item, { resend: false }); }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-magnify ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.DETAIL))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"orange","small":"","icon":""},on:{"click":function () { return _vm.showDialogFormOracle(item, { resend: true }); }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-cached ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.oracleData.RESEND_TO_ORACLE))])])]}}],null,true)}),_c('DialogFormObjectInputOracle',{ref:"dialogFormObjectInputOracle"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }