<template>
  <section>
    <p class="ml-2 mt-2">{{ title }}</p>
    <v-row justify="end" class="mt-4 mb-2">
      <v-col cols="12" sm="5" md="4">
        <v-text-field
          dense
          hide-details
          outlined
          class="caption"
          v-model="search"
          :placeholder="$_strings.oracleData.SEARCH_INVOICE_ORACLE"
          @keyup.enter="handleSearch"
        >
          <template v-slot:prepend-inner>
            <v-icon @click="handleSearch">
              mdi-magnify
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      calculate-widths
      :loading="isLoading"
      :headers="headers"
      :items="items"
      :server-items-length="totalData"
      :options.sync="pagination"
      :footer-props="{
        showCurrentPage: true,
        showFirstLastPage: true,
        'items-per-page-options': $_item_per_page
      }"
    >
      <template v-slot:[`item.action`]={item}>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="primary"
              small
              icon
              @click="() => showDialogFormOracle(item, { resend: false })"
            >
              <v-icon>
                mdi-magnify
              </v-icon>
            </v-btn>
          </template>
          <span>{{$_strings.common.DETAIL}}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="orange"
              small
              icon
              @click="() => showDialogFormOracle(item, { resend: true })"
            >
              <v-icon>
                mdi-cached
              </v-icon>
            </v-btn>
          </template>
          <span>{{$_strings.oracleData.RESEND_TO_ORACLE}}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <DialogFormObjectInputOracle ref="dialogFormObjectInputOracle" />
  </section>
</template>
<script>
import {
  dateFormat,
  handleSortBy,
  handlerPagination,
  defaultPagination,
  skipEmptyStringObject,
} from '@/helper/commonHelpers';
import DialogFormObjectInputOracle from '../Dialog/FormObjectInputOracle';

export default {
  components: {
    DialogFormObjectInputOracle,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    filter: {
      type: Object,
      default: () => {},
    },
    source: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      search: this.$route.query.search || '',
      isLoading: false,
      headers: [
        {
          text: this.$_strings.oracleData.INVOICE_NO,
          value: 'invoiceNo',
          class: 'white--text primary text-capitalize',
          width: '180px',
        },
        {
          text: this.$_strings.oracleData.CUSTOMER_NAME,
          value: 'billedToCompanyName',
          class: 'white--text primary text-capitalize',
          width: '250px',
        },
        {
          text: this.$_strings.oracleData.INVOICE_DATE,
          value: 'invoiceDate',
          class: 'white--text primary text-capitalize',
          width: '200px',
        },
        {
          text: this.$_strings.common.ACTION,
          value: 'action',
          sortable: false,
          class: 'white--text primary text-capitalize',
          width: '180px',
        },
      ],
      items: [],
      totalData: 0,
      pagination: defaultPagination(),
    };
  },
  watch: {
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        this.fetchData();
      },
      deep: true,
    },
  },
  methods: {
    handleSearch() {
      const { search } = this.$route.query;
      if (search !== this.search) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            search: this.search,
            page: 1,
          },
        });
        this.$emit('refetchData');
      }
    },
    async showDialogFormOracle(item, { resend }) {
      const res = await this.fetchDetail(item.id);
      this.$refs.dialogFormObjectInputOracle.dialog = true;
      this.$refs.dialogFormObjectInputOracle.showBtnResend = resend;
      this.$refs.dialogFormObjectInputOracle.form.id = item.id;
      this.$refs.dialogFormObjectInputOracle.form.jsonBody = JSON.stringify(res.data, null, 2);
    },
    async fetchData() {
      if (this.isLoading) return;
      try {
        this.isLoading = true;
        const { type } = this.$route.params;
        const { search } = this.$route.query;
        const {
          page, itemsPerPage: size, sortBy, sortDesc,
        } = this.pagination;
        const _filter = skipEmptyStringObject({
          ...this.filter,
          page: page - 1,
          size,
          sort: handleSortBy({ sortBy, sortDesc }),
        });
        if (search) _filter.invoiceNo = `qlike(${search})`;
        const result = await this.$_services.oracleData.getListInvoiceOracle(_filter, type, this.source);
        this.items = result.data.contents.map((e) => ({
          ...e,
          invoiceDate: dateFormat(e.invoiceDate),
        }));
        this.totalData = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
    async fetchDetail(id) {
      try {
        this.$root.$loading.show();
        const result = await this.$_services.oracleData.getDetailInvoiceOracle(id);
        return result;
      } finally {
        this.$root.$loading.hide();
      }
    },
  },
};
</script>
